export default {
    "scales": {
        "design": {
            "showName": "Проектная",
            "templatesFolder": "componentsCompany/cherkizovo/elementScales/design",
            "events": {
                "CARD_ELEMENT_EVENT_AFTER_CREATE": "app\\build\\cherkizovo\\scales\\handlers\\ElementCreateHandler"
            }
        },
        "progressive": {
            "showName": "Прогрессивная",
            "templatesFolder": "componentsCompany/cherkizovo/elementScales/progressive",
            "events": []
        }
    },
    "scales_id_threshold": 1073741823,
    "scale_settings_template": "componentsCompany/cherkizovo/scalesSettings/ScalesSettings"
}