export default {
    "export": {
        "library_folder": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\FolderExportService",
            "permissions": []
        },
        "cards": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardsExportService",
            "permissions": []
        },
        "card": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardExportService",
            "permissions": []
        }
    },
    "import": {
        "library_folder": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\FolderImportService",
            "permissions": [],
            "example": "library.xlsx"
        },
        "card_elements": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardElementsImportService",
            "closedRoute": false,
            "permissions": [],
            "example": "card.xlsx"
        },
        "card_elements_closed_route": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardElementsImportService",
            "closedRoute": true,
            "permissions": [],
            "example": "card.xlsx"
        },
        "card_elements_library": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardElementsLibraryImportService",
            "permissions": [],
            "example": "card_library.xlsx"
        },
        "users_selection_by_excel": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\UsersSelectionByExcelService",
            "enabled": true,
            "permissions": [],
            "example": "users_selection.xlsx"
        }
    }
}