export default {
    "entities": [
        {
            "showName": "Пользователи",
            "reportKey": "users"
        },
        {
            "showName": "Карты",
            "reportKey": "cards"
        },
        {
            "showName": "Цели в карте",
            "reportKey": "card_elements"
        }
    ],
    "providers": {
        "array": "app\\components\\MassActions\\providers\\ArrayDataProvider",
        "report_builder": {
            "entityPreviewSettings": {
                "users": {
                    "relations": [
                        [
                            "users"
                        ],
                        [
                            "users",
                            "meta"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователи",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "position",
                            "relationName": "Пользователи",
                            "name": "Должность"
                        }
                    ]
                },
                "cards": {
                    "relations": [
                        [
                            "cards"
                        ],
                        [
                            "cards",
                            "info"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "cards"
                            ],
                            "block": "cards",
                            "field": "id",
                            "relationName": "Карты",
                            "name": "ID"
                        },
                        {
                            "relationsChain": [
                                "cards",
                                "user"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователь",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "cards",
                                "user"
                            ],
                            "block": "users",
                            "field": "position",
                            "relationName": "Пользователь",
                            "name": "Должность"
                        }
                    ]
                },
                "card_elements": {
                    "relations": [
                        [
                            "card_elements"
                        ],
                        [
                            "card_elements",
                            "section"
                        ],
                        [
                            "card_elements",
                            "section",
                            "card"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "card_elements"
                            ],
                            "block": "card_elements",
                            "field": "id",
                            "relationName": "Показатели",
                            "name": "ID"
                        },
                        {
                            "relationsChain": [
                                "card_elements"
                            ],
                            "block": "card_elements",
                            "field": "name",
                            "relationName": "Показатели",
                            "name": "Название"
                        },
                        {
                            "relationsChain": [
                                "card_elements",
                                "section",
                                "card",
                                "user"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователь",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "card_elements",
                                "section",
                                "card",
                                "user"
                            ],
                            "block": "users",
                            "field": "position",
                            "relationName": "Пользователь",
                            "name": "Должность"
                        },
                        {
                            "relationsChain": [
                                "card_elements",
                                "section",
                                "card"
                            ],
                            "block": "cards",
                            "field": "id",
                            "relationName": "Карта",
                            "name": "ID"
                        }
                    ]
                }
            }
        },
        "file": "app\\components\\MassActions\\providers\\FileDataProvider"
    },
    "actions": {
        "cards": {
            "set_stage": {
                "showName": "Запуск этапов карты КПЭ",
                "template": "components/massActions/actionTemplates/card/CardStartStageAction"
            },
            "set_negotiation": {
                "showName": "Перевод карты с шага на шаг",
                "template": "components/massActions/actionTemplates/card/CardGoToNegotiationAction"
            },
            "end_stage": {
                "showName": "Завершение этапа карты",
                "template": "componentsCompany/cherkizovo/massActions/actionTemplates/card/CardEndStageAction"
            },
            "delete": {
                "showName": "Удаление карт",
                "template": "components/massActions/actionTemplates/card/CardDeleteAction"
            },
            "create_element_from_library": {
                "showName": "Добавление КПЭ из библиотеки в карты",
                "template": "components/massActions/actionTemplates/card/CardAddElementFromLibraryAction"
            },
            "update_card": {
                "showName": "Изменение года карты",
                "template": "components/massActions/actionTemplates/card/CardUpdateAction",
                "allowedFields": [
                    "year"
                ]
            },
            "update_stage_negotiation_user": {
                "showName": "Изменение ответственного за шаг",
                "template": "components/massActions/actionTemplates/card/CardUpdateNegotiationAction"
            },
            "delete_stage_negotiation": {
                "showName": "Удаление шага в карте",
                "template": "components/massActions/actionTemplates/card/CardDeleteNegotiationAction"
            }
        },
        "users": {
            "update_subordination": {
                "showName": "Изменить орг. структуру",
                "template": "components/massActions/actionTemplates/user/UserSetChiefAction",
                "templateData": {
                    "select_placeholder": "Выберите тип руководителя"
                }
            },
            "clear_subordination": {
                "showName": "Удалить связь",
                "template": "components/massActions/actionTemplates/user/UserRemoveChiefAction"
            }
        },
        "card_elements": {
            "delete_element": {
                "showName": "Удаление КПЭ",
                "template": "components/massActions/actionTemplates/cardElement/CardElementDeleteAction"
            },
            "create_comment": {
                "showName": "Создание комментария",
                "template": "components/massActions/actionTemplates/cardElement/CardElementCreateCommentAction"
            }
        }
    }
}