export default {
    "types": {
        "1": {
            "showName": "Создана"
        },
        "10": {
            "showName": "Постановка целей"
        },
        "20": {
            "showName": "Постановка целей завершена"
        },
        "30": {
            "showName": "Корректировка целей"
        },
        "40": {
            "showName": "Корректировка целей завершена"
        },
        "50": {
            "showName": "Подведение итогов"
        },
        "200": {
            "showName": "Подведение итогов завершено"
        }
    }
}