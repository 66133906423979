export default {
    "available_periods": {
        "1": "Месяц",
        "3": "Квартал",
        "6": "Полугодие",
        "12": "Год"
    },
    "available_sections_for_period": {
        "1": {
            "basic": {
                "selected": false
            },
            "manual": {
                "selected": false
            }
        },
        "3": {
            "basic": {
                "selected": false
            },
            "manual": {
                "selected": false
            }
        },
        "6": {
            "basic": {
                "selected": false
            },
            "manual": {
                "selected": false
            }
        },
        "12": {
            "basic": {
                "selected": false
            },
            "manual": {
                "selected": false
            }
        }
    },
    "card_limit_for_user_per_period": 0,
    "cards_create_info_template": "componentsCompany/cherkizovo/CardsCreateInfo",
    "medium_facts": {
        "available": false
    },
    "card_recount_bonus_service": "app\\build\\cherkizovo\\card\\services\\CardRecountBonusService",
    "cards_visible_permissions_criteria_map": {
        "card.see_if_participate": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardIParticipateCriteria",
        "card.see_self": "app\\build\\cherkizovo\\rbac\\criteria\\CardMyCriteria",
        "card.see_subordinates": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardAllSubordinatesCriteria",
        "card.see_chiefs": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardMyChiefsCriteria",
        "card.see_subordinates_not_closed_route": "app\\build\\cherkizovo\\rbac\\criteria\\CardSubordinateNotClosedRouteCriteria",
        "card.see_subordinates_closed_route": "app\\build\\cherkizovo\\rbac\\criteria\\CardSubordinateClosedRouteCriteria"
    },
    "cards_search_criteria": [
        "app\\build\\cherkizovo\\card\\criteria\\CardsUsersFiredCriteria"
    ],
    "cards_page_filters": {
        "template": "componentsCompany/cherkizovo/CardsPageFilters",
        "search_fields": {
            "cards.id": {
                "condition": "=",
                "type": "number"
            },
            "users.last_name": {
                "relation": "user"
            },
            "users.first_name": {
                "relation": "user"
            },
            "users.position": {
                "relation": "user"
            }
        },
        "default_sort_params": {
            "attributes": {
                "id": {
                    "asc": {
                        "cards.id": 4
                    },
                    "desc": {
                        "cards.id": 3
                    }
                },
                "0": "year",
                "user.last_name": {
                    "asc": {
                        "users.last_name": 4
                    },
                    "desc": {
                        "users.last_name": 3
                    }
                }
            },
            "defaultOrder": {
                "id": 3
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 60
        },
        {
            "label": "Табельный номер",
            "prop": "user.meta.tabnr",
            "width": 150
        },
        {
            "label": "ФИО",
            "template": "components/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate",
            "prop": "user.last_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Год",
            "prop": "year"
        },
        {
            "label": "Период",
            "template": "components/tableColumnTemplates/cards/PeriodColumnTemplate"
        },
        {
            "label": "Этап",
            "template": "components/tableColumnTemplates/cards/CurrentStageColumnTemplate",
            "prop": "currentStage.type"
        },
        {
            "label": "Ответственный",
            "template": "components/tableColumnTemplates/cards/CurrentNegotiationColumnTemplate"
        },
        {
            "label": "Индивидуальный результат",
            "prop": "bonus"
        },
        {
            "template": "components/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_elements": [
        {
            "label": "Сотрудник",
            "icon": "fas fa-id-card-alt",
            "prop": "user.full_name"
        },
        {
            "label": "Табельный номер",
            "icon": "fas fa-list-ol",
            "prop": "user.meta.tabnr"
        },
        {
            "label": "Должность",
            "icon": "fas fa-briefcase",
            "prop": "user.position"
        },
        {
            "label": "HR BP",
            "icon": "fas fa-user-tie",
            "prop": "user.chiefs.hrbp.full_name"
        },
        {
            "label": "Функциональный руководитель",
            "icon": "fas fa-user-check",
            "prop": "user.chiefs.functional.full_name"
        },
        {
            "label": "Функциональный руководитель + 1",
            "icon": "fas fa-user",
            "prop": "user.chiefs.senior.full_name"
        }
    ],
    "card_selectable_page_columns": []
}