export default {
    "chief_types": {
        "hrbp": {
            "showName": "Бизнес-партнёр по персоналу"
        },
        "functional": {
            "showName": "Функциональный руководитель"
        },
        "kpi_responsible": {
            "showName": "Ответственный за KPI (закрытый маршрут)"
        }
    },
    "my_chiefs_types": {
        "hrbp": [],
        "functional": []
    },
    "my_employees_types": {
        "hrbp": [],
        "functional": [],
        "kpi_responsible": []
    },
    "users_search_criteria": [
        "app\\build\\cherkizovo\\user\\criteria\\UsersFiredCriteria"
    ],
    "users_visible_permissions_criteria_map": {
        "user.see_self": "app\\domain\\User\\criteria\\returnInNewQuery\\UserSelfCriteria",
        "user.see_subordinates": "app\\domain\\User\\criteria\\returnInNewQuery\\UserAllSubordinatesCriteria",
        "user.see_chiefs": "app\\domain\\User\\criteria\\returnInNewQuery\\UserChiefsCriteria"
    },
    "users_list_columns": [
        {
            "label": "Табельный номер",
            "prop": "meta.tabnr"
        },
        {
            "label": "ФИО",
            "prop": "full_name"
        },
        {
            "label": "Должность",
            "prop": "position"
        },
        {
            "label": "Email",
            "prop": "email"
        }
    ],
    "users_list_default_role_filter_in_search": "user",
    "sort_params": {
        "attributes": {
            "0": "id",
            "1": "company",
            "full_name": {
                "asc": {
                    "last_name": 4
                },
                "desc": {
                    "last_name": 3
                }
            }
        },
        "defaultOrder": {
            "full_name": 4
        }
    },
    "chiefs_list_columns": [
        {
            "label": "Табельный номер",
            "prop": "user.meta.tabnr"
        },
        {
            "label": "ФИО",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Email",
            "prop": "user.email"
        },
        {
            "label": "Табельный номер руководителя",
            "prop": "chief.meta.tabnr"
        },
        {
            "label": "Руководитель",
            "prop": "chief.full_name"
        }
    ],
    "chiefs_search_fields": {
        "users.last_name": {
            "relation": "user"
        },
        "users.first_name": {
            "relation": "user"
        },
        "users.middle_name": {
            "relation": "user"
        },
        "users.position": {
            "relation": "user"
        },
        "chiefs.last_name": {
            "relation": "chief chiefs"
        },
        "chiefs.first_name": {
            "relation": "chief chiefs"
        },
        "chiefs.middle_name": {
            "relation": "chief chiefs"
        }
    },
    "chiefs_sort_params": {
        "attributes": {
            "user.company": {
                "asc": {
                    "users.company": 4
                },
                "desc": {
                    "users.company": 3
                }
            },
            "user.full_name": {
                "asc": {
                    "users.last_name": 4
                },
                "desc": {
                    "users.last_name": 3
                }
            }
        },
        "defaultOrder": {
            "user.full_name": 4
        }
    }
}