export default {
    "visible_auth_methods": [
        "loginPass",
        "oauth1"
    ],
    "auth_methods": {
        "loginPass": [],
        "oauth1": {
            "domain": "https://fs.cherkizovo.com/adfs",
            "clientId": "9708aeb4-9334-447d-9c4b-5eac08e0e700",
            "redirectUri": "https://goals-test.cherkizovo.com/login",
            "resource": "https://goals-test.cherkizovo.com/login",
            "service": "app\\components\\Auth\\services\\Oauth1AuthService",
            "authField": "email",
            "template": "auth/methods/oauth1"
        }
    }
}